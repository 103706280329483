import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdStars } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Victoria Sea Club
			</title>
			<meta name={"description"} content={"Перемещайтесь по волнам, захватывайте день - Victoria Sea, ваше морское спасение ждет вас."} />
			<meta property={"og:title"} content={"Главная | Victoria Sea Club"} />
			<meta property={"og:description"} content={"Перемещайтесь по волнам, захватывайте день - Victoria Sea, ваше морское спасение ждет вас."} />
			<meta property={"og:image"} content={"https://voxeton.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxeton.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxeton.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxeton.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="rgba(0, 0, 0, 0) url(https://voxeton.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					Victoria Sea Club
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 50px 0px 50px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Откройте для себя радость моря вместе с нами. От легкого утреннего бриза до золотого заката - наш выбор однодневных яхт предлагает идеальное судно для вашего морского приключения. В Victoria Sea мы верим в создание моментов, которые останутся в вашей памяти на долгие годы.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						href="#contact"
						type="link"
						text-decoration-line="initial"
					>
						Связаться
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-align-items="center"
				md-width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-margin="30px 0px 30px 0px"
				sm-width="100%"
				width="75%"
				lg-width="80%"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				sm-justify-content="center"
			>
				<Text
					margin="0px 0px 8px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
				>
					Погрузитесь в безмятежность с арендой яхт Victoria Sea
				</Text>
				<Text
					lg-text-align="left"
					font="normal 700 45px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-text-align="center"
					margin="0px 0px 0px 0px"
					color="--darkL2"
				>
					Отправляйтесь в путешествие, где горизонт - это только начало. Наш флот, идеально подходящий для однодневных или выходных экскурсий, обещает сочетание комфорта и приключений. Мы не просто арендуем яхты - мы даем обещание бескрайних океанских красот вам и вашим близким.
				</Text>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-width="100%"
				sm-order="-1"
				justify-content="space-between"
				md-width="30%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="20%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					width="100%"
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					border-radius="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
				>
					<Image
						width="100%"
						top="0px"
						right={0}
						min-height="100%"
						bottom="auto"
						position="absolute"
						object-fit="cover"
						display="block"
						left={0}
						src="https://voxeton.com/img/2.jpg"
					/>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
			>
				<Box
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						width="100%"
						right={0}
						src="https://voxeton.com/img/3.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-15"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 24px 0px"
				sm-justify-content="flex-start"
			>
				<Text
					as="p"
					font="--lead"
					color="#3f24d8"
					margin="0px 0px 8px 0px"
					sm-text-align="left"
				>
					<Strong letter-spacing="0.5px">
						Про Нас
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Почему стоит выбрать аренду яхт в Victoria Sea?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdStars}
								size="20px"
								color="#3f24d8"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong letter-spacing="0.5px">
								Интимные морские моменты
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
						Ощутите великолепие океана в личном масштабе, идеальном для создания воспоминаний.

						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdStars}
								size="20px"
								color="#3f24d8"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
								Упрощенное плавание
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
						Простые в управлении яхты и дружелюбное руководство сделают ваше морское путешествие таким же беззаботным, как и побережье.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdStars}
								size="20px"
								color="#3f24d8"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
								Доступная роскошь
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
						Наслаждайтесь тонкостями морского отдыха без роскошных ценников.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});